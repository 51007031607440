* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}